import React from 'react';
import { MarkDownElement } from '../../../../service/converts/markdown';
import './style.scss';
interface Props {
  answers: string[];
  answerCorrect?: string;
  handleAnswer?: (answer?: any) => void;
}
const QuestionSelect = (props: Props) => {
  const { answers, answerCorrect, handleAnswer } = props;

  const OptionAnswer = (answer: string, index: number) => {
    return (
      <div className="col-md-3 col-sm-6  select-option" key={index}>
        <span className="pre-answer">{PRE_ANSWER_VIEW[index]}</span>
        {MarkDownElement(answer)}
      </div>
    );
  };
  return (
    <div className=" question-select">
      <div className="row">{answers.map((answer, index) => OptionAnswer(answer, index))}</div>
    </div>
  );
};

export default QuestionSelect;
const PRE_ANSWER = ['A', 'B', 'C', 'D'];
const PRE_ANSWER_VIEW = ['A.', 'B.', 'C.', 'D.'];
