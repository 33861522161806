import React, { useEffect, useState } from 'react';

import './index.scss';
import Latex from 'react-latex-next';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import remardGFM from 'remark-gfm';
import { MathpixMarkdownModel } from 'mathpix-markdown-it';
import ImageElement, { parseImage } from './image';
import { ListGroupItem } from 'react-bootstrap';
import { parseTable, TableConvert } from '../table';

interface Props {
  element: {
    Type: string;
    Content: string;
  };
}

export const TemplateKatex: React.FC<Props> = ({ element }) => {
  return (
    <span className="latex">
      <Latex
        delimiters={[
          { left: '$$', right: '$$', display: true },
          { left: '\\(', right: '\\)', display: false },
          { left: '$', right: '$', display: false },
          { left: '\\[', right: '\\]', display: true },
        ]}>
        {element.Content}
      </Latex>
    </span>
  );
};
export const MarkDown = (props: Props) => {
  const { element } = props;

  const texts = element.Content.replace(/(\\[ubi]{1,3})\{([^]*?)\}/g, (text: string, type = '', value = '') => {
    let valueText = value;
    let types = [] as string[];
    types = type.split('');
    types.shift();
    types.forEach((item) => {
      valueText = `<${item}>${valueText}</${item}>`;
    });
    return valueText;
  }).split(/(\\(?:image|audio|media|video|href)\[[^]*?\]\{[^]*?\})/g);

  return (
    <>
      {texts.map((text) => {
        let jsx;
        if (/(\\(?:image|audio|media|video|href)\[[^]*?\]\{[^]*?\})/g.test(text)) {
          let element;
          text.replace(/\\(\w+)[^]*?/g, (t, type) => {
            switch (type) {
              case 'image':
                const imgData = parseImage(text);

                element = <ImageElement {...imgData} />;
            }
            return t;
          });
          return element;
        } else {
          return <TemplateKatex element={{ Type: 'block', Content: text }} />;
        }
      })}
    </>
  );
};
export const MarkDownElement = (text: string, index?: number) => {
  if (/(\\begin\{table\}[^]*?\\end\{table\})/g.test(text)) {
    let dataTable = parseTable(text);
    return <TableConvert {...dataTable} key={index} />;
  } else {
    return <MarkDown element={{ Type: 'block', Content: text }} key={index} />;
  }
};
