import React, { useEffect, useState } from 'react';
import QuestionSelect from './question-select';
interface Props {
  text: string;
  handleAnswer?: () => void;
}
const QuestionElement = (input: Props) => {
  const { text } = input;
  const [element, setElement] = useState();
  useEffect(() => {
    setElement(getTypeQuestion(text) as any);
  }, [text]);
  const getTypeQuestion = (str: string) => {
    let questionElement = {
      answers: [],
      type: '',
    };

    str.replace(/(!)?\{\{([^]*?)\}\}(?:\((.*?)\))?/i, (text, pre, value, post) => {
      let options = [] as string[];
      if (post) {
        options = post.split(',');
        options = options.map((val) => val.trim());
        post = options.join(',');
      }
      if (options.includes('question-type')) {
      } else {
        //dạng select
        questionElement = { answers: value.split(/\//g), type: 'select' };
      }
      return text;
    });
    return questionElement;
  };
  const getQuestionElement = (element: { type: string; answers: string[] }) => {
    switch (element.type) {
      case 'select':
        return <QuestionSelect {...element} />;
      default:
        return <QuestionSelect {...element} />;
    }
  };
  return <div>{element && getQuestionElement(element as any)}</div>;
};

export default QuestionElement;
