import React, { useEffect } from 'react';
import './style.scss';
/**
 *
 * \image[\width{} \heigth{} \className{} \title{} \link{}]{src}
 */
interface Props {
  src: string;
  width: number;
  height: number;
  className?: string;
}
export const parseImage = (str: string) => {
  let data = {} as any;

  str.replace(/\\image\[([^]*?)\]\{([^]*?)\}/g, (text, options: string, src) => {
    if (/(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/g.test(src)) {
      data.src = src;
    }
    options.replace(/([^\s]*?)\{(.*?)\}/g, (text_value, type: string, value: any) => {
      data[type] = value;
      console.log(type, value);

      return text_value;
    });
    return text;
  });

  return data as Props;
};
const ImageElement = (props: Props) => {
  const { src, width, height, className } = props;
  useEffect(() => {
    console.log(src);
  }, [props]);
  return (
    <span className={className}>
      <img className={`gdoc-image `} src={src} width={width} height={height} />
    </span>
  );
};

export default ImageElement;
