import { table } from 'node:console';
import { stringify } from 'node:querystring';
import React, { useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';
import ContentEditable from 'react-contenteditable';
import ReactDOM from 'react-dom';
import { questionAdminApi } from '../../../api/admin/question.api';
import Question from '../../../components/question';
import { QuestionDto } from '../../../dtos/question';
import { MarkDown, MarkDownElement, TemplateKatex } from '../../../service/converts/markdown';
import { parseTable, TableConvert, TableData } from '../../../service/converts/table';
import { KatexConst } from '../../../settings/staticData/katex_const';
import './style.scss';
// tham khảo https://katex.org/docs/supported.html
const question_katex = /\$[^]*?\$/g;
const question_table = /\\table\{[^]*?\}/g;

const question_style = /\\[ubi]{1,3}\{[^]*?\}/g;
const gdocUrl =
  'https://docs.google.com/document/d/1dKFF_tQvLFQiQuLMv4shLDnkX9LgO5FGOCx2W6BxaRE/edit#heading=h.k0xpdqkkq4ed';
const tableRun = `
  \\begin{table}[] 1\n \\col{2} \\row{1} &  3\n \\col{1} \\row{1} \\\\
  3\n \\col{1} \\row{1} & 3\n \\col{1} \\row{1} & 3\n \\col{1} \\row{1} \\\\ \\end{table}`;
const tableText = `\\begin{table}[] 1\\col{1} \\row{1}  & 2 & 3 \\\\ 4 & 5 & 6 \\\\ 7 & 8 & 9 \\\\
  \\end{table}`;
const HomePage = () => {
  const [questions, setQuestions] = useState([] as QuestionDto[]);
  useEffect(() => {
    questionAdminApi.updateQuestionsByGdoc({ url: gdocUrl }).then((response) => {
      if (response.status == 200) {
        setQuestions(response.data.questions);
      }
    });
    console.log(tableText);
  }, []);

  return (
    <div className="container">
      <div className="input-text">
        <div>
          {questions.map((question, index) => (
            <Question {...question} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
