import React, { useEffect, useState } from 'react';
import { QuestionDto } from '../../dtos/question';
import { MarkDownElement } from '../../service/converts/markdown';
import QuestionElement from './question-element';
import './index.scss';

const Question = (questionInput: QuestionDto) => {
  const [question, setQuestion] = useState(questionInput);
  useEffect(() => {
    setQuestion(questionInput);
  }, [questionInput]);
  return (
    <div className="question">
      <div className="introduct">
        <Introduct str={question.item.introduction} />
      </div>
      <div className="content">
        <Content str={question.item.content} index={questionInput.index} />
      </div>
    </div>
  );
};
const Introduct = (props: { str?: string }) => {
  const { str } = props;
  const [texts, setTexts] = useState(parseHtml(str || ''));
  useEffect(() => {
    setTexts(parseHtml(props.str || ''));
  }, [props]);

  return <>{texts.map((text) => MarkDownElement(text))}</>;
};
const Content = (props: { str?: string; index: number }) => {
  const { str } = props;
  const [texts, setTexts] = useState(parseHtml(str || ''));
  useEffect(() => {}, [texts]);
  useEffect(() => {
    setTexts(parseHtml(props.str || ''));
  }, [props]);
  return (
    <>
      {texts.map((text, index) =>
        regexAnswers.test(text) == true ? (
          <QuestionElement key={props.index} text={text} />
        ) : (
          MarkDownElement(text, props.index)
        ),
      )}
    </>
  );
};
const parseHtml = (str: string) => {
  str = str
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, ' ')
    .replace(/&quot;/g, '"')
    .replace(/(\n)/g, '$\\newline$')
    .replace(/<div>|<br>/g, '$\\newline$')
    .replace(/(\<[^]*?\>)/g, (text: string, type: any) => '')
    .replace(/<\/div>/g, '')
    .replace(/&#039;/g, "'")
    .replaceAll('\\/', '&#47;');

  const texts = str
    .split(/(\\begin\{table\}[^]*?\\end\{table\}(?:\$\\newline\$)?)|(?:!)?(\{\{[^]*?\}\}(?:\(.*?\))?)/i)
    .filter((i) => i && i !== '$\\newline$');
  return texts;
};
const regexAnswers = /\{\{[^]*?\}\}(?:\(.*?\))?/i;

export default Question;
