import React, { useState } from 'react';
import { RowProps } from 'react-bootstrap';
import { MarkDown, TemplateKatex } from '../markdown';
import './style.scss';

// text = \table{[item]/[]/.../}(w, h,  x- y - z - t,name)
// [] = (a,c,d,e,f)
/* tableinput : {
    type : table
}
ex : 
[1, 2, 4\col(2)]
[2, 3, 4, 5]
[3, 4, 5, 6]
*/

export interface TableData {
  width: number;
  height: number;
  name: string;
  rows?: RowData[];
  cols?: number[];
  css: string;
  className?: string;
}
interface RowData {
  items?: ItemRowData[];
}
interface ItemRowData {
  text: string;
  col?: number;
  row?: number;
  className?: string;
}
/**
 * \begin{table}[
 * \width{}
 * \class{}
 * \col{a|b|c|d}
 * ]
 *
 * 
 * 
    1\col{1} \row{1}  & 2 & 3 \\
     4 & 5 & 6 \\
     7 & 8 & 9\\
 
  \end{table}
 */
export const parseTable = (str: string) => {
  let options;
  let datatabe;
  // console.log(str);
  let tableData = {} as TableData;

  str.replace(
    /\\begin\{table\}\[([^]*?)\]([^]*?)\\end\{table\}/g,
    (text: string, options: string, datatableText: string) => {
      options = options;
      datatabe = datatableText;
      // console.log({ options, datatableText });

      let optionsTable = options.split(/(\\[^]*?\{[^]*?\})/g).filter((i) => i && i != '');

      optionsTable.forEach((i) => {
        i.replace(/\\([^]*?)\{([^]*?)\}/, (t, type, value) => {
          switch (type) {
            case 'class':
              tableData.className = value;
              break;
            case 'width':
              tableData.width = Number(value);
              break;
            case 'col':
              tableData.cols = value.split('|').filter((v: string) => v && v != ' ');
              break;
          }
          return t;
        });
      });
      let dataRows = datatableText
        .split(/(?:\$\\newline\$)?([^]*?)\\{2}?/g)
        .filter((v) => v && v.trim() !== '' && v != '$\\newline$')
        .map((v) => v.trimEnd().trimStart());

      tableData.rows = dataRows.map((row) => {
        let colsText = row
          .split(/([^]*?)\&{1}?/g)
          .filter((v) => v && v.trim() !== '' && v != '$\\newline$')
          .map((v) => v.trimEnd().trimStart().replace('\\&', '&'));

        let colsData = colsText.map((col) => {
          let cold = {
            text: '',
            col: 1,
            row: 1,
            className: '',
          } as any;
          col = col.replaceAll('\\0026', '&'); // convert về dâu &

          let colTexts = col.split(/(\\[^\$\[\]ibu]*?\{[^]*?\})/g).filter((i) => i);

          colTexts.forEach((t) => {
            if (/(\\(?:image|audio|media|video|href)\[[^]*?\]\{[^]*?\})/g.test(t)) {
              cold.text += t;
            }
            if (/(\\[^ibu]*?\{[^]*?\})/g.test(t)) {
              t.replace(/\\([^]*?)\{(.*?)\}/g, (text_value, type: string, value: any) => {
                if (type === 'image') {
                  cold.text += text_value;
                  return text_value;
                }
                cold[type] = value;
                return text_value;
              });
            } else {
              cold.text += t;
            }
          });
          console.log(cold);

          return cold as ItemRowData;
        });
        // console.log(colsData);

        return {
          items: colsData,
        } as RowData;
      });

      return text;
    },
  );

  return tableData;
};
export const TableConvert = (props: TableData) => {
  const { width, height, name, rows, css, className } = props;
  const renderRow = (row: RowData) => {
    return <tr>{row.items?.map(renderItemRow)}</tr>;
  };
  const renderItemRow = (item: ItemRowData) => {
    const { text, col, row, className } = item;
    return (
      <td colSpan={col} rowSpan={row} className={className || ''}>
        <MarkDown element={{ Type: 'block', Content: text }} />
      </td>
    );
  };
  return (
    <div className={`table-render table-render-${width}px ${className || ' '}`}>
      <table className="table-custom table-bordered">
        <tbody>{rows?.map(renderRow)}</tbody>
      </table>
    </div>
  );
};
